import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'
import { Loader2 } from 'lucide-react'

export type ButtonVariant =
  | 'default'
  | 'success'
  | 'destructive'
  | 'outline'
  | 'outlinePrimary'
  | 'outlineDestructive'
  | 'secondary'
  | 'ghost'
  | 'link'
  | 'linkDestructive'
  | 'dashed'
export type ButtonSize = 'default' | 'xs' | 'sm' | 'lg' | 'xl' | 'icon'
const buttonVariants = cva(
  'inline-flex shadow-sm items-center justify-center rounded-md text-sm font-medium -tracking-[0.01em] transition-all focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-gradient-to-b from-primary/90 to-primary text-primary-foreground border border-white/20 shadow-sm hover:brightness-110',
        success:
          'bg-green-500 text-background border border-black/10 hover:bg-green-500/90 hover:text-background',
        destructive:
          'bg-background text-destructive border border-destructive hover:bg-destructive hover:text-destructive-foreground',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        outlinePrimary: 'border border-primary bg-background text-primary',
        outlineDestructive:
          'border border-destructive bg-background text-destructive',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-muted hover:text-accent-foreground shadow-none',
        link: 'text-foreground underline-offset-4 hover:underline',
        linkDestructive: 'text-destructive underline-offset-4 hover:underline',
        dashed:
          'text-foreground border border-dashed border-border hover:bg-muted',
      },
      size: {
        default: 'h-10 px-4 py-2',
        xs: 'h-8 rounded-md px-2 text-xs',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        xl: 'h-12 rounded-md px-8 text-base',
        icon: 'h-10 w-10',
        'icon-sm': 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
  loadingText?: string
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading = false,
      loadingText = 'Submitting',
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={loading || props.disabled}
        {...props}
      >
        {loading ? (
          <>
            <Loader2 className="animate-spin w-4 h-4 mr-2" /> {loadingText}
          </>
        ) : (
          children
        )}
      </Comp>
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
